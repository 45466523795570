<template>
  <b-form @submit.prevent="loginFormSubmit">
    <AppLogo />

    <b-input-group class="my-3">
      <b-input-group-prepend>
        <b-input-group-text>
          <username-icon></username-icon>
        </b-input-group-text>
      </b-input-group-prepend>
      <b-form-input
        autocomplete="username email"
        class="form-control"
        placeholder="Username"
        ref="usernameField"
        type="text"
        v-model="credentials.username"
      />
    </b-input-group>
    <b-input-group class="mb-4">
      <b-input-group-prepend>
        <b-input-group-text>
          <password-icon></password-icon>
        </b-input-group-text>
      </b-input-group-prepend>
      <b-form-input
        autocomplete="current-password"
        class="form-control"
        placeholder="Password"
        type="password"
        v-model="credentials.password"
      />
    </b-input-group>

    <loading-button
      :disabled="disabledButton"
      :loading="loadingButton"
      block
      button-class="px-4"
      variant="success"
    >
      Login
    </loading-button>
  </b-form>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import {
  LoadingButton,
  PasswordIcon, UsernameIcon, AppLogo
} from '@/components'

const MAX_THROTTLE_USER = 5

export default {
  name: 'LoginForm',
  components: {
    AppLogo,
    LoadingButton,
    PasswordIcon,
    UsernameIcon
  },
  data () {
    return {
      credentials: {
        username: null,
        password: null
      },
      loadingButton: false,
      disabledButton: false
    }
  },
  watch: {
    getUserThrottle (throttle) {
      this.$nextTick(() => {
        if (throttle > MAX_THROTTLE_USER) {
          this.preventLogin()
        }
      })
    }
  },
  computed: {
    ...mapState('users', {
      loginAttempt: 'throttle',
      authenticated: 'authenticated'
    }),
    ...mapGetters('users', {
      getUserThrottle: 'getUserThrottle'
    })
  },
  methods: {
    ...mapMutations('users', {
      setModalThrottle: 'setModalThrottle',
      throttleSignIn: 'throttle',
      flushThrottle: 'flushThrottle'
    }),
    resetForm () {
      this.credentials = {
        username: null,
        password: null
      }

      this.setModalThrottle(false)
      this.loginButtonState(false)
    },
    preventLogin () {
      this.setModalThrottle(true)

      this.disabledButton = true
      this.loadingButton = false

      setTimeout(() => {
        this.flushThrottle()
        this.credentials.username = null
        this.credentials.password = null
        this.$refs.usernameField.focus()
        this.resetForm()
      }, 60000)
    },
    loginButtonState (state) {
      this.loadingButton = state
      this.disabledButton = state
    },
    loginFormSubmit () {
      if (this.credentials.username && this.credentials.password) {
        this.throttleSignIn()
        this.loginButtonState(true)
        if (this.getUserThrottle <= MAX_THROTTLE_USER) {
          this.$emit('onSubmit', this.$data)
        }
      }
    }
  }
}
</script>
