<template>
  <div>
    <dl class="row" id="detail">
      <dt class="col-lg-4">ID</dt>
      <dd class="col-lg-8">{{ detail.id }}</dd>
      <dt class="col-lg-4">Nama Pengguna</dt>
      <dd class="col-lg-8">{{ detail.username }}</dd>
      <dt class="col-lg-4">Surel</dt>
      <dd class="col-lg-8">
        <span v-if="hasEmailAddress">{{ detail.email_address }}</span>
        <span v-else class="font-weight-bold">-</span>
      </dd>
      <dt class="col-lg-4">Status</dt>
      <dd class="col-lg-8">{{ detail.role }}</dd>
      <dt class="col-lg-4">Terakhir Masuk</dt>
      <dd class="col-lg-8">{{ detail.last_signed_in | ago }}</dd>
    </dl>
    <dl class="row" id="profile"
        v-if="detail.role === 'dosen'"
    >
      <dt class="col-lg-4">NIDN</dt>
      <dd class="col-lg-8">{{ profile.dsnNidn }}</dd>
      <dt class="col-lg-4">NIP</dt>
      <dd class="col-lg-8">{{ profile.dsnPegNip }}</dd>
      <dt class="col-lg-4">Nama Lengkap</dt>
      <dd class="col-lg-8">{{ concatName(profile) }}</dd>
      <dt class="col-lg-4">Jenis Kelamin</dt>
      <dd class="col-lg-8">{{ profile.pegJenisKelaminKode | gender }}</dd>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { momentAgo, lectureNameConcat, isNil } from '@/helpers'

export default {
  name: 'DetailProfile',
  filters: {
    ago: (val) => momentAgo(val),
    gender: (val) => {
      const listGender = {
        L: 'Laki-laki',
        P: 'Perempuan'
      }

      return listGender[val]
    }
  },
  computed: {
    ...mapGetters({
      detail: 'users/getUserDetail',
      profile: 'users/getUserProfile'
    }),
    hasEmailAddress () {
      return !isNil(this.detail.email_address)
    }
  },
  methods: {
    concatName (name) {
      return lectureNameConcat(
        name.pegNama,
        name.pegGelarBelakang,
        name.pegGelarDepan
      )
    }
  }
}
</script>
