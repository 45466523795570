<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group
      label="Password"
      :invalid-feedback="errors.password"
    >
      <b-form-input
        v-model="model.password"
        :state="errorPasswordState"
        required
        type="password"
      />
    </b-form-group>
    <b-form-group
      label="Ulangi Password"
      :invalid-feedback="errors.password_confirmation"
    >
      <b-form-input
        v-model="model.password_confirmation"
        :state="errorPasswordState"
        required
        type="password"
      />
    </b-form-group>
    <c-button variant="primary" button-type="submit">
      Ganti Password
    </c-button>
  </b-form>
</template>

<script>
import { mapObjIndexed } from '@/helpers'
import { UserService as ApiService } from '@/services'
import { Button as CButton } from '@/components/buttons'

export default {
  name: 'PasswordForm',
  components: {
    CButton
  },
  data () {
    return {
      model: {
        password: null,
        password_confirmation: null
      },
      errors: {
        password: null,
        password_confirmation: null
      }
    }
  },
  computed: {
    errorPasswordState () {
      if (this.model.password !== null &&
        this.model.password_confirmation !== null) {
        return this.errors.password === null &&
          this.errors.password_confirmation === null
      }

      return null
    }
  },
  methods: {
    onSubmit () {
      ApiService.putChangePassword(this.model)
        .then(() => {
          this.errors = {
            password: null,
            password_confirmation: null
          }
          this.model = {
            password: null,
            password_confirmation: null
          }
          this.$emit('afterSubmit')
        })
        .catch((err) => {
          this.$nextTick(() => {
            const firstMsg = msg => msg[0]
            this.errors = mapObjIndexed(firstMsg, err.data.errors)
          })
        })
    }
  }
}
</script>
