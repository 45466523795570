<template>
  <b-row>
    <b-col>
      <b-card header="Pengguna">
        <b-alert
          v-if="isEmailNotExist"
          :show="showMessageEmail"
          dismissible
          variant="success"
        >
          Berhasil mengubah surel anda.
          Mohon ikuti link yang dikirim ke surel anda untuk konfirmasi alamat surel saat ini.
        </b-alert>
        <b-alert
          v-if="isEmailNotVerified"
          :show="isEmailNotVerified"
          variant="info"
        >
          <h4 class="alert-heading">Anda belum verifikasi surel!</h4>
          <p>Kirim link untuk verifikasi alamat surel.</p>
          <c-button variant="outline-primary" @onClick="resendMail">Kirim Verifikasi</c-button>
        </b-alert>
        <DetailProfile class="mb-2" />

        <EmailForm v-if="isEmailNotExist" @afterSubmit="afterSubmitEmail" />
      </b-card>
    </b-col>
    <b-col>
      <b-card header="Password">
        <b-alert
          :show="showMessagePassword"
          dismissible
          variant="success"
        >
          Berhasil mengubah password anda.
        </b-alert>
        <PasswordForm @afterSubmit="afterSubmitPassword" />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UserService } from '@/services'
import { Button as CButton } from '@/components/buttons'
// module
import PasswordForm from '@/modules/auth/PasswordForm'
import EmailForm from '@/modules/auth/EmailForm'
import DetailProfile from '@/modules/auth/DetailProfile'

export default {
  name: 'Profile',
  components: {
    DetailProfile,
    EmailForm,
    PasswordForm,
    CButton
  },
  data () {
    return {
      showMessageEmail: false,
      showMessagePassword: false
    }
  },
  computed: {
    ...mapGetters({
      isEmailNotExist: 'users/getUserEmailNotExist',
      emailNotVerified: 'users/getUserEmailNotVerified'
    }),
    isEmailNotVerified () {
      return this.emailNotVerified && this.isEmailNotExist === false
    }
  },
  beforeMount () {
    this.getUserDetail()
  },
  methods: {
    ...mapActions({
      getUserDetail: 'users/detail'
    }),
    afterSubmitEmail () {
      this.showMessageEmail = true
    },
    afterSubmitPassword () {
      this.showMessagePassword = true
    },
    resendMail () {
      return UserService.postResendMail()
    }
  }
}
</script>
