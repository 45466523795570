<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group
      label="Alamat Surel *"
      description="Anda belum memiliki alamat surel."
      :invalid-feedback="errors.email_address"
    >
      <b-form-input
        v-model="model.email_address"
        :state="errorEmailState"
        required
        type="email"
      />
    </b-form-group>
    <c-button variant="primary" button-type="submit">
      Verifikasi Alamat Surel
    </c-button>
  </b-form>
</template>

<script>
import { mapObjIndexed } from '@/helpers'
import { Button as CButton } from '@/components/buttons'
import { UserService as ApiService } from '@/services'

export default {
  name: 'EmailForm',
  components: {
    CButton
  },
  data () {
    return {
      model: { email_address: null },
      errors: { email_address: null }
    }
  },
  computed: {
    errorEmailState () {
      if (this.model.email_address !== null) {
        return this.errors.email_address === null
      }

      return null
    }
  },
  methods: {
    onSubmit () {
      ApiService.putChangeEmail(this.model)
        .then(() => {
          this.errors = { email_address: null }
          this.model = { email_address: null }
          this.$emit('afterSubmit')
        })
        .catch((err) => {
          this.$nextTick(() => {
            const firstMsg = msg => msg[0]
            this.errors = mapObjIndexed(firstMsg, err.data.errors)
          })
        })
    }
  }
}
</script>
