<template>
  <div class="app flex-row align-items-center">
    <b-alert
      v-model="modalThrottle"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="danger"
    >
      <danger-icon />
      <span class="ml-2">{{ throttleMessage }}</span>
    </b-alert>

    <div class="container">
      <div class="d-flex justify-content-center">
        <div class="card shadow-sm">
          <div class="card-body">
            <LoginForm @onSubmit="loginProcess" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { DangerIcon } from '@/components'
// module
import LoginForm from '@/modules/auth/LoginForm'

export default {
  name: 'Login',
  components: {
    LoginForm,
    DangerIcon
  },
  computed: {
    ...mapState('users', [
      'modalThrottle',
      'throttleMessage'
    ])
  },
  methods: {
    ...mapActions('users', {
      signin: 'signin'
    }),
    redirectIfAuthenticated () {
      setTimeout(() => {
        this.$router.push('/dashboard').catch(() => {})
      }, 1000)
    },
    loginProcess (form) {
      this.signin(form.credentials)
        .then(() => {
          this.redirectIfAuthenticated()
        })
        .catch((err) => {
          form.disabledButton = false
          form.loadingButton = false

          this.$bvToast.toast(
            err.response.data.message, {
              title: 'Login failed',
              toaster: 'b-toaster-top-center',
              variant: 'warning',
              autoHideDelay: 5000,
              appendToast: false
            }
          )
        })
    }
  }
}
</script>

<style scoped>
body {
  /* background-image: url(
    '//gallery.unand.ac.id/_data/i/upload/2015/02/04/20150204113724-3ca7dd80-me.jpg'
  ); */

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
