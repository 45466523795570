<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h4 class="pt-3">
              Verifkasi Surel Berhasil!
            </h4>
            <p class="text-muted">
              Silahkan kembali ke halaman profil. <br>
              <router-link :to="{ name: 'main.user-profile' }">
                <i class="icon-action-undo" />
                Kembali
              </router-link>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'VerifikasiSurel',
  created () {
    this.verify(this.$route.params)
  },
  methods: {
    ...mapActions({
      verifyUserMail: 'users/verify'
    }),
    verify (params) {
      this.verifyUserMail(params)
    }
  }
}
</script>
