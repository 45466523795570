<template>
  <p class="p-2">
    Mengalihkan...
  </p>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'OAuth2Callback',
  methods: {
    ...mapActions({
      oauthCallback: 'users/oauthCallback'
    })
  },
  mounted () {
    const query = this.$route.query

    this.oauthCallback({
      user: JSON.parse(query.user),
      accessToken: query.accessToken,
      expiresIn: query.tokenExpires
    }).then(() => {
      this.$router.push({
        name: 'main.dashboard'
      }).catch(() => {})
    })
  }
}
</script>
