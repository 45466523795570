<template>
  <p class="p-2">
    Logging out...
  </p>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',
  methods: {
    ...mapActions({
      signOut: 'users/signout'
    })
  },
  mounted () {
    this.signOut().then(() => {
      this.$router.push({
        name: 'auth.login'
      }).catch(() => {})
    })
  }
}
</script>
